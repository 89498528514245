<template>
  <router-view :logged-user="loggedUser" />
</template>

<script>
import netlifyIdentity from "netlify-identity-widget";
import { mapGetters, mapActions } from "vuex";
netlifyIdentity.init({
  APIUrl: "https://homeclean.nl/.netlify/identity",
  logo: true, // you can try false and see what happens
});

export default {
  name: "App",
  data() {
    return {
      isLoggedIn: false,
      token: "",
      currentUser: null,
    };
  },
  computed: {
    ...mapGetters("user", {
      isLoggedIn: "getUserStatus",
      user: "getUser",
    }),
    username() {
      return this.user ? this.user.username : ", there!";
    },
  },
  methods: {
    ...mapActions("user", {
      updateUser: "updateUser",
    }),
    triggerNetlifyIdentityAction(action) {
      if (action == "login" || action == "signup") {
        netlifyIdentity.open(action);
        netlifyIdentity.on(action, (user) => {
          this.currentUser = {
            username: user.user_metadata.full_name,
            email: user.email,
            access_token: user.token.access_token,
            expires_at: user.token.expires_at,
            refresh_token: user.token.refresh_token,
            token_type: user.token.token_type,
          };
          this.updateUser({
            currentUser: this.currentUser,
          });
          netlifyIdentity.close();
        });
      } else if (action == "logout") {
        this.currentUser = null;
        this.updateUser({
          currentUser: this.currentUser,
        });
        netlifyIdentity.logout();
        this.$router.push({ name: "Home" });
      }
    },
  },
};
</script>

<style lang="scss"></style>
