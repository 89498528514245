import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
    import(/* webpackChunkName: "Webpage" */ "../views/HomeView.vue"),
},
  {
    path: "/informatie",
    name: "Informatie",
    component: () =>
      import(/* webpackChunkName: "Webpage" */ "../views/InformatieView.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "Webpage" */ "../views/ContactView.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "Webpage" */ "../views/AboutView.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Webpage" */ "../views/LoginView.vue"),
  },
  {
    path: "/referal/:referalid",
    name: "Referal",
    component: () =>
      import(/* webpackChunkName: "Webpage" */ "../views/ReferalView.vue"),
  },
  {
    path: "/Dashboard/",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "../views/DashboardView.vue"),
    children: [
      {
        path: "/Dashboard/home",
        name: "Dashboard Home",

        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "../views/Dashboard/HomeView.vue"
          ),
      },
      {
        path: "/Dashboard/organisatie",
        name: "Organization",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "../views/Dashboard/OrganisatieView.vue"
          ),
      },
      {
        path: "/Dashboard/reports",
        name: "Reports",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "../views/Dashboard/ReportsView.vue"
          ),
      },
      {
        path: "/Dashboard/customers",
        name: "Customers",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "../views/Dashboard/CustomersView.vue"
          ),
        children: [
          {
            path: "/Dashboard/customers/modal",
            component: () =>
              import(
                /* webpackChunkName: "Components" */ "../components/ModalOne.vue"
              ),
          },
        ],
      },
      {
        path: "/Dashboard/region",
        name: "Region",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "../views/Dashboard/RegionView.vue"
          ),
      },
      {
        path: "/Dashboard/finance",
        name: "Finance",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "../views/Dashboard/FinanceView.vue"
          ),
      },
      {
        path: "/Dashboard/agenda",
        name: "Agenda",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "../views/Dashboard/AgendaView.vue"
          ),
      },
    ],
  },
  {
    path: "/addUser",
    name: "AddUser",
    component: () =>
      import(
        /* webpackChunkName: "Components" */ "../components/ModalWRole1.vue"
      ),
  },
  {
    path: "/",
    name: "clients",
    component: () =>
      import(
        /* webpackChunkName: "Components" */ "../components/ClientList1.vue"
      ),
  },
  {
    path: "/dashboard/client/:id",
    name: "client-details",
    component: () =>
      import(
        /* webpackChunkName: "Components" */ "../components/ClientDetails1.vue"
      ),
  },
  {
    path: "/add",
    name: "add",
    component: () =>
      import(/* webpackChunkName: "Components" */ "../components/ModalOne.vue"),
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_ROUTER_URL),
  routes,
});



export default router;
